export const STAGE = process.env.NODE_ENV || 'development';
export const SITE_NAME = 'Gypsy Mode';
export const SITE_DESCRIPTION =
  'Welkom in de comfy en cosy wereld van Gypsy Mode. Neem snel een kijkje in de webshop en ontdek onze kleurrijke funky oversized dameskleding.';
export const SITE_ROOT = 'https://gypsymode.be';
export const API_ENDPOINT = 'https://webshop-api-master-b2fzg2xtcq-ew.a.run.app/';
// STAGE === 'production' ? 'https://webshop-api-master-b2fzg2xtcq-ew.a.run.app/' : 'http://localhost:4000/';
export const SHOP_ID = '5d8c7a36e3a6fc844635aa78'; // STAGE === 'production' ? '5d8c7a36e3a6fc844635aa78' : '5d8c7a36e3a6fc844635aa77';
export const GRAPHQL_ENDPOINT = API_ENDPOINT + 'graphql';
export const CONTACT_EMAIL = 'info@gypsymode.be';
export const FREE_SHIPPING_TRESHOLD = 75;

// Static invalidation times
export const SHORT_INVALIDATION_TIME = 1;
export const LONG_INVALIDATION_TIME = 3600;
export const VERY_LONG_INVALIDATION_TIME = 86400;
export const LOGO_URL = '/static/logo.png';
