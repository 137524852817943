import React from 'react';

import Link from '../elements/link';
import CrossIcon from '../../icons/cross.svg';
import { readItem, writeItem } from '../../utils/localstorage';

const LOCALSTORAGE_DISCLAIMER_KEY = '__gypsy_disclaimer_v1';

export default function CookiePopup() {
  let [showPopup, setShowPopup] = React.useState(true);

  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      let hideDisclaimer = readItem(LOCALSTORAGE_DISCLAIMER_KEY);
      if (hideDisclaimer) {
        setShowPopup(false);
      }
    }
  }, [typeof window]);

  const hideDisclaimer = () => {
    if (typeof window !== 'undefined') {
      writeItem(LOCALSTORAGE_DISCLAIMER_KEY, { hide: true });
      setShowPopup(false);
    }
  };

  return showPopup ? (
    <div
      className="fixed z-10 bottom-0 left-0 px-6 py-2 w-full bg-desert-sand text-white flex justify-between"
      onClick={hideDisclaimer}
    >
      <span>
        Door deze website te gebruiken ga je akkoord met ons <Link href="/policies/cookies">cookies</Link> en{' '}
        <Link href="/policies/privacy">privacy</Link> policy.
      </span>
      <span onClick={hideDisclaimer} className="cursor-pointer">
        <CrossIcon className="h-6 w-6" />
      </span>
    </div>
  ) : null;
}
