import React from 'react';
import classNames from 'classnames';
import Link, { LinkProps } from 'next/link';

const PRIMARY_CLASSNAME = 'border-desert-sand bg-isabelline hover:bg-desert-sand hover:text-white text-raw-umber';
const DISABLED_CLASSNAME = 'cursor-default bg-gray-200 border-transparent text-gray-400';
const ICON_CLASSNAME = 'border-transparent bg-transparent text-raw-umber hover:text-raw-umber';

type ButtonType = 'primary' | 'icon';

interface ButtonStylingProps {
  buttonType?: ButtonType;
  isDisabled?: boolean;
  className?: string;
}

function getButtonStyling(opts: ButtonStylingProps) {
  let { buttonType, isDisabled, className } = opts;

  return classNames(
    'block border-2 font-medium flex justify-center items-center',
    {
      ['p-2']: buttonType === 'icon',
      ['py-2 px-6']: buttonType !== 'icon',
      [PRIMARY_CLASSNAME]: !isDisabled && buttonType === 'primary',
      [ICON_CLASSNAME]: !isDisabled && buttonType === 'icon',
      [DISABLED_CLASSNAME]: isDisabled,
    },
    className
  );
}

interface ButtonProps extends ButtonStylingProps {
  children: React.ReactNode;
  onSubmit?: () => any;
  type?: 'button' | 'reset' | 'submit';
  isLoading?: boolean;
  ariaLabel?: string;
}

const Button: React.FC<ButtonProps> = (props) => {
  let { children, onSubmit, type, isDisabled, className, buttonType = 'primary', isLoading, ariaLabel } = props;

  const handleSubmit = () => {
    if (!isDisabled && onSubmit) {
      onSubmit();
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLButtonElement>) => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  };

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    handleSubmit();
  };

  return (
    <button
      className={getButtonStyling({ isDisabled, className, buttonType })}
      onClick={handleClick}
      onKeyDown={handleKeyDown}
      type={type}
      disabled={isDisabled}
      aria-label={ariaLabel}
    >
      {isLoading ? <div className="spinner">children</div> : children}
    </button>
  );
};

interface LinkButtonProps extends LinkProps, ButtonStylingProps {
  children: React.ReactNode;
}

export const LinkButton: React.FC<LinkButtonProps> = (props) => {
  let { isDisabled, className, buttonType = 'primary', children, ...otherProps } = props;

  return (
    <Link {...otherProps} className={getButtonStyling({ isDisabled, className, buttonType })}>
      {children}
    </Link>
  );
};

export default Button;
