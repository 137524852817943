import { useRouter } from 'next/router';

export type Locale = 'nl' | 'fr';

export function useLocale(): Locale {
  const router = useRouter();
  let locale = (router.locale ?? 'nl').toLowerCase();
  if (!['nl', 'fr'].includes(locale)) {
    locale = 'nl';
  }
  // @ts-ignore
  return locale;
}
