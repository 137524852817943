import React from 'react';
import classNames from 'classnames';

type Props = {
  notificationType?: 'error' | 'warning' | 'success' | 'info';
  title?: React.ReactNode;
  children?: React.ReactNode;
  className?: string;
  onClose?: () => any;
};

export default function Notification(props: Props) {
  let { title, children, className, notificationType = 'info', onClose } = props;

  const handleClick = () => onClose && onClose();

  return (
    <div
      className={classNames(
        'shadow w-64 p-3 mt-2',
        {
          ['bg-isabelline text-raw-umber']: notificationType === 'info',
          ['bg-green-600 text-white']: notificationType === 'success',
          ['bg-yellow-600 text-white']: notificationType === 'warning',
          ['bg-red-600 text-white']: notificationType === 'error',
        },
        className
      )}
      onClick={handleClick}
    >
      <div className="mb-1 font-medium text-lg">{title}</div>
      <div>{children}</div>
    </div>
  );
}
