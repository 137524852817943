import React from 'react';
import classNames from 'classnames';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import splitbee from '@splitbee/web';

import Button from '../elements/button';
import { usePageContext } from '../../context/page-provider';
import ShoppingBagIcon from '../../icons/shopping-bag.svg';
import NotificationContainer from './notification-container';
import SEO from './seo';
import graphqlClient from '../../utils/graphql-client';
import MobileMenuIcon from '../../icons/mobile-menu.svg';
import CrossIcon from '../../icons/cross.svg';
import { captureException } from '../../pages/_app';
import { CategoryObjectType } from '../../../generated/graphql';
import { FREE_SHIPPING_TRESHOLD, SITE_ROOT } from '../../constants';
import { useTranslate } from '../../hooks/useTranslate';
import { useLocale } from '../../hooks/useLocale';

export interface Props {
  title?: string;
  description?: string;
  canonical?: string;
  image: string;
}

const categoryQuery = `query ListAllCategoriesQuery {
  listCategories(inStock: true) {
    _id
    name {
      nl
      fr
    }
    picture
    showOnOverview
    createdAt
    updatedAt
  }
}`;

const NavItem: React.FC<{ href: string; label: string }> = (props) => {
  let { href, label } = props;

  return (
    <a href={href} className="block w-full hover:text-raw-umber py-1 font-medium underline" aria-label={label}>
      {label}
    </a>
  );
};

const NavBar: React.FC<{ showNav: boolean; setShowNav: (value: boolean) => void }> = (props) => {
  const { showNav, setShowNav } = props;
  const [categories, setCategories] = React.useState<Array<CategoryObjectType>>([]);
  const translate = useTranslate();
  const locale = useLocale();

  React.useEffect(() => {
    (async () => {
      try {
        let { listCategories } = (await graphqlClient.request(categoryQuery)) as any;
        setCategories(listCategories);
      } catch (err) {
        console.error(err);
        captureException(err);
      }
    })();
  }, [true]);

  return (
    <div
      className={classNames('w-screen h-screen fixed z-50 left-0 top-0 cursor-pointer', {
        hidden: !showNav,
      })}
      style={{
        backgroundColor: 'rgba(0, 0, 0 ,0.2)',
      }}
      onClick={(evt) => {
        evt.stopPropagation();

        setShowNav(false);
      }}
    >
      <nav
        className="bg-desert-sand h-screen max-w-screen block w-full md:w-1/2 lg:w-1/4 cursor-default"
        onClick={(evt) => {
          evt.stopPropagation();
        }}
      >
        <div
          className="grid grid-cols-3 items-center p-2 bg-antique-brass h-16 cursor-pointer"
          onClick={() => {
            setShowNav(false);
          }}
        >
          <div></div>
          <div className="flex justify-center text-xl font-medium uppercase pl-6">Menu</div>
          <div className="flex justify-end">
            <CrossIcon className="w-8 h-8" />
          </div>
        </div>
        <div className="mt-4 px-8">
          {categories.map((cat) => {
            return <NavItem href={`/${locale}/products?category=${cat._id}`} label={cat.name[locale]} key={cat._id} />;
          })}

          <div className="mt-12">
            <NavItem href={`/${locale}/products`} label={translate('allClothing')} />
            <NavItem href={`/${locale}/products?isOutlet=1`} label="Sales" />
          </div>
        </div>
      </nav>
    </div>
  );
};

const HighlightsBar: React.FC = () => {
  const translate = useTranslate();

  return (
    <div className="bg-cambridge-blue-200 tracking-wide text-white text-md uppercase font-medium mb-4 py-2">
      <div className="text-center">
        {translate('freeShipping', { treshold: '' + FREE_SHIPPING_TRESHOLD })}
        <a href="/policies/terms-of-sale">*</a>
      </div>
    </div>
  );
};

export const Header = (props: Props) => {
  const { title, description, canonical, image } = props;
  const Router = useRouter();
  const [showNav, setShowNav] = React.useState(false);
  const { cart } = usePageContext();

  const mobileMenuIconClassnames = 'inline-block h-6 w-6 text-white';
  const imageUrl = new URL(image, SITE_ROOT);

  return (
    <React.Fragment>
      <SEO title={title} description={description} canonical={canonical} image={imageUrl.toString()} />

      <header className="bg-desert-sand text-white">
        <div className="w-full">
          <div className="w-full max-w-6xl mx-auto grid grid-cols-3 h-16 px-2">
            <div className="flex justify-start">
              <Button
                buttonType="icon"
                onSubmit={() => {
                  splitbee.track('Open navigation menu');
                  setShowNav(!showNav);
                }}
                ariaLabel={showNav ? 'Sluit menu' : 'Open menu'}
              >
                {showNav ? (
                  <CrossIcon className={mobileMenuIconClassnames} />
                ) : (
                  <MobileMenuIcon className={mobileMenuIconClassnames} />
                )}
                <span className="hidden sm:inline-block mx-2 font-sans text-white text-lg">Menu</span>
              </Button>
            </div>

            <NavBar setShowNav={setShowNav} showNav={showNav} />

            <div className="flex justify-center">
              <NextLink href="/" className="flex items-center text-3xl font-serif uppercase" aria-label="Go to home">
                Gypsy
              </NextLink>
            </div>

            <div className="flex justify-end">
              <div className="flex items-center justify-between">
                <div className="relative">
                  <Button buttonType="icon" onSubmit={() => Router.push('/shopping-bag')}>
                    <ShoppingBagIcon className="inline-block h-6 w-6 text-white" />
                    <span className="text-xs absolute right-0 bottom-0 rounded-full bg-cambridge-blue-200 text-white w-4 h-4 flex justify-center content-center">
                      {cart.length > 9 ? '9+' : cart.length}
                    </span>
                  </Button>
                  <NotificationContainer />
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      <HighlightsBar />
    </React.Fragment>
  );
};

export default Header;
