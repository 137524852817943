import { GraphQLClient } from 'graphql-request';

import { GRAPHQL_ENDPOINT, SHOP_ID } from '../constants';

function initGraphQLClient() {
  let headers: { [key: string]: string } = {};

  headers.shop_id = SHOP_ID;

  return new GraphQLClient(GRAPHQL_ENDPOINT, {
    headers,
    fetch,
  });
}

let client = initGraphQLClient();
export default client;
