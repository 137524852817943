export const translations = {
  freeShipping: 'Gratis verzending vanaf {{treshold}} euro',
  allClothing: 'Alle kleding',
  viewAllClothing: 'Bekijk alle kleding',
  contactUs: 'Neem contact op',
  reflectionPeriod: '14 dagen bedenktijd',
  categories: 'Categorieën',
  backTo: 'Terug naar {{name}}',
  addToCart: 'Voeg toe aan winkelmandje',
  description: 'Beschrijving',
  size: 'Maat',
  toCart: 'Naar winkelmandje',
  language: 'Taal',
  notifications: {
    productAddedTitle: 'Product toegevoegd',
    productAddedContent: '{{productName}} is in uw winkelmandje gestopt',
  },
  shoppingBag: {
    title: 'Jouw winkelmandje',
    noItems: 'Jouw winkelmandje is leeg',
    goShopping: 'Ga winkelen',
    discountCode: 'Kortingscode',
    apply: 'Toepassen',
    placeOrder: 'Plaats bestelling',
    totalAmount: 'Totaalbedrag',
    inclVat: 'Inclusief btw',
    invalidDiscountCode: 'Ongeldige kortingscode',
    unknownDiscountError: 'Kon kortingscode niet toepassen',
    extraMessage: `Hey hey,\n\nJe bent er bijna! Dubbelcheck eerst of je al je gewenste items hebt, niks vergeten?\nKijk dan zeker ook je adres eens na. Heel belangrijk\nSales items worden niet geretourneerd of terugbetaald, dat zijn de regeltjes, maar toch efkes herhalen.\nGaan met die banaan nu, gauw afrekenen, zodat je zeker bent van je favorietjes.\n\nDankjewel voor je bestelling!`,
  },
  shippingDetails: {
    title: 'Verzendgegevens',
    name: 'Voornaam',
    familyName: 'Familienaam',
    email: 'E-mailadres',
    streetAndNumber: 'Straat en huisnummer',
    city: 'Gemeente',
    postalCode: 'Postcode',
    country: 'Land',
    countryCodes: {
      be: 'België',
      nl: 'Nederland',
      fr: 'Frankrijk',
    },
  },
  deliveryMethod: {
    title: 'Leveringsmethode',
    selectPickupPoint: 'Kies een afhaalpunt',
    noPickupPointsFound: 'Kon geen afhaalpunten vinden, ben je zeker dat je adres correct is?',
    deliveryAddress: 'Leveringsadres',
    freeFrom: 'Gratis vanaf {{amount}}',
    options: {
      bpost: 'Thuislevering',
      homeDelivery: 'Thuislevering',
      pickup: 'Afhalen in Roeselare (op afspraak)',
      pickupPoint: 'Afhalen aan een afhaalpunt',
    },
  },
  orderOverview: {
    title: 'Bestellingsoverzicht',
    pay: 'Betaal',
    shippingFee: 'Verzendkosten',
    discount: 'Korting',
  },
  orderCompletionPage: {
    cancelled: {
      title: 'Uw bestelling is geannuleerd',
      description: 'Uw bestelling is geannuleerd. U kan uw bestelling opnieuw plaatsen.',
    },
    expired: {
      title: 'Uw bestelling is verlopen',
      description:
        'We hebben uw betaling niet op tijd ontvangen en automatisch de bestelling geanulleerd. U kan uw bestelling opnieuw plaatsen.',
    },
    paymentFailed: {
      title: 'Uw betaling is mislukt',
      description: 'Uw betaling is mislukt. U kan uw bestelling opnieuw plaatsen.',
    },
    completed: {
      title: 'Bedankt voor je bestelling',
      description: 'U zal binnen enkele ogenblikken een bevestiging via mail ontvangen.',
    },
    processing: {
      title: 'Uw bestelling word verwerkt',
      description:
        'Uw bestelling wordt verwerkt, als de betaling succesvol is gebeurd zult u binnen enkele ogenblikken een email ontvangen.',
    },
    supportFooter: 'Mocht je vragen hebben, neem dan gerust contact met ons op',
  },
  policies: {
    termsOfSale: 'Verkoopsvoorwaarden',
    privacyPolicy: 'Privacybeleid',
    cookiePolicy: 'Cookiebeleid',
  },
};
