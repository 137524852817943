import type { TranslationsMap } from './types';

export const translations: TranslationsMap = {
  freeShipping: 'Livraison gratuite à partir de {{treshold}} euros',
  allClothing: 'Tous les vêtements',
  viewAllClothing: 'Voir tous les vêtements',
  contactUs: 'Contactez-nous',
  reflectionPeriod: 'Période de réflexion de 14 jours',
  categories: 'Catégories',
  backTo: 'Retour à {{name}}',
  addToCart: 'Ajouter au panier',
  description: 'Description',
  size: 'Taille',
  toCart: 'Au panier',
  language: 'Langue',
  shoppingBag: {
    title: 'Votre panier',
    noItems: 'Votre panier est vide',
    goShopping: 'Faire du shopping',
    discountCode: 'Code de réduction',
    apply: 'Appliquer',
    placeOrder: 'Passer la commande',
    totalAmount: 'Montant total',
    inclVat: 'TVA incluse',
    invalidDiscountCode: 'Code de réduction invalide',
    unknownDiscountError: "Impossible d'appliquer le code de réduction",
    extraMessage: `Cou cou,\n\nTu y es presque! Vérifiez d’abord si vous avez tout ce que vous voulez, rien oublié?\nAssurez vous que votre adresse soit bien correcte, c’est très important\nLes articles soldés ne sont ni repris ni remboursés, telles sont les règles, mais je les répète quand même.\nAllons y, vite payer pour être certain de vos petits favoris\n\nJe vous remercie pour votre commande!`,
  },
  notifications: {
    productAddedTitle: 'Produit ajouté',
    productAddedContent: '{{productName}} a été ajouté à votre panier',
  },
  shippingDetails: {
    title: 'Détails de livraison',
    name: 'Prénom',
    familyName: 'Nom de famille',
    email: 'Adresse e-mail',
    streetAndNumber: 'Rue et numéro',
    city: 'Ville',
    postalCode: 'Code postal',
    country: 'Pays',
    countryCodes: {
      be: 'Belgique',
      nl: 'Pays-Bas',
      fr: 'France',
    },
  },
  deliveryMethod: {
    title: 'Méthode de livraison',
    selectPickupPoint: 'Choisissez un point de collecte',
    noPickupPointsFound: `Vous n'avez pas trouvé de points de collecte, êtes-vous sûr que votre adresse est correcte?`,
    deliveryAddress: 'Adresse de livraison',
    freeFrom: 'Gratuit à partir de {{amount}}',
    options: {
      bpost: 'Livraison à domicile',
      homeDelivery: 'Livraison à domicile',
      pickup: 'Ramassage à Roeselare (sur rendez-vous)',
      pickupPoint: 'Ramassage à un point de collecte',
    },
  },
  orderOverview: {
    title: 'Aperçu de la commande',
    pay: 'Payer',
    shippingFee: 'Frais de port',
    discount: 'Remise',
  },
  orderCompletionPage: {
    cancelled: {
      title: 'Votre commande a été annulée',
      description: 'Votre commande a été annulée. Vous pouvez passer une nouvelle commande.',
    },
    expired: {
      title: 'Votre commande a expiré',
      description:
        'Nous n’avons pas reçu votre paiement à temps et avons automatiquement annulé la commande. Vous pouvez passer une nouvelle commande.',
    },
    paymentFailed: {
      title: 'Votre paiement a échoué',
      description: 'Votre paiement a échoué. Vous pouvez passer une nouvelle commande.',
    },
    completed: {
      title: 'Merci pour votre commande',
      description: 'Vous recevrez une confirmation par e-mail dans quelques instants.',
    },
    processing: {
      title: 'Votre commande est en cours de traitement',
      description:
        'Votre commande est en cours de traitement, si le paiement a réussi, vous recevrez un e-mail dans quelques instants.',
    },
    supportFooter: 'Si vous avez des questions, n’hésitez pas à nous contacter',
  },
  policies: {
    termsOfSale: 'Conditions de vente',
    privacyPolicy: 'Politique de confidentialité',
    cookiePolicy: 'Politique de cookies',
  },
};
