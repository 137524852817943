import React from 'react';
import NextLink, { LinkProps } from 'next/link';
import classNames from 'classnames';

interface Props extends LinkProps {
  children: React.ReactNode;
  className?: string;
  isExternal?: boolean;
}

export const RawLink: React.FC<Props> = (props) => {
  let { href, as, children, className, isExternal } = props;

  if (isExternal) {
    return (
      // @ts-ignore
      <a className={className} href={href} onClick={(evt) => evt.stopPropagation()}>
        {children}
      </a>
    );
  } else {
    return (
      <NextLink href={href} as={as} className={className} onClick={(evt) => evt.stopPropagation()}>
        {children}
      </NextLink>
    );
  }
};

export default function Link(props: Props) {
  let { className } = props;

  let styling = classNames('underline hover:text-raw-umber', className);
  return <RawLink {...props} className={styling} />;
}
