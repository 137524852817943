import React from 'react';
import Head from 'next/head';
import normalizeUrl from 'normalize-url';

import { SITE_NAME, SITE_DESCRIPTION, API_ENDPOINT, SITE_ROOT } from '../../constants';
import { useRouter } from 'next/router';

export interface Props {
  title?: string;
  description?: string;
  canonical?: string;
  image: string;
}

export default function SEO(props: Props) {
  const router = useRouter();
  const description = (props.description ?? SITE_DESCRIPTION).substring(0, 160);
  const titleText = props.title ? `${props.title} | ${SITE_NAME}` : SITE_NAME;
  const canonicalUrl = normalizeUrl(`${SITE_ROOT}/${props.canonical ?? router.asPath}`, { forceHttps: true });

  return (
    <Head>
      <link rel="shortcut icon" href="/favicon.ico" />
      <link rel="preconnect" href={API_ENDPOINT}></link>
      <link rel="preconnect" href="https://connect.facebook.net"></link>
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta name="description" content={description} />
      <title>{titleText}</title>

      <link rel="canonical" href={canonicalUrl} />

      <meta name="og:title" content={titleText} />
      <meta name="og:description" content={description} />
      <meta name="og:site_name" content={SITE_NAME} />
      <meta name="og:url" content={canonicalUrl} />
      <meta name="og:type" content="website" />
      <meta property="og:image" content={props.image} />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={titleText} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={props.image} />
    </Head>
  );
}
