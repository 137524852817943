import React from 'react';

import Link from '../elements/link';
import FBIcon from '../../icons/social/facebook.svg';
import IGIcon from '../../icons/social/instagram.svg';
import MailIcon from '../../icons/mail.svg';
import CheckIcon from '../../icons/check.svg';
import { CONTACT_EMAIL, FREE_SHIPPING_TRESHOLD } from '../../constants';
import { useTranslate } from '../../hooks/useTranslate';
import { useLocaleUtils } from '../../hooks/useLocaleUtils';
import { Option, Select } from '../elements/select';

export default function Footer() {
  const translate = useTranslate();
  const { locale, changeLocale } = useLocaleUtils();

  return (
    <footer className="bg-isabelline text-raw-umber">
      <div className="w-full max-w-6xl mx-auto px-4 pt-4 pb-12 flex flex-col items-center">
        <div className="mb-6 font-medium flex flex-wrap items-center justify-center">
          <div className="flex items-center">
            <CheckIcon className="w-4 h-4 mr-1 text-success" />
            {translate('freeShipping', { treshold: '' + FREE_SHIPPING_TRESHOLD })}
          </div>
          <div className="flex items-center ml-4">
            <CheckIcon className="w-4 h-4 mr-1 text-success" />
            {translate('reflectionPeriod')}
            <a href="/policies/terms-of-sale">*</a>
          </div>
        </div>

        <div className="sm:flex">
          <div className="sm:flex">
            <div className="text-center mb-6 sm:mr-12">
              <div className="font-sans font-medium">{translate('contactUs')}</div>
              <div className="flex justify-center items-center">
                <Link href="https://www.facebook.com/gypsymode/" className="p-2" isExternal>
                  <FBIcon className="w-8 h-8" />
                  <span className="sr-hide">Facebook</span>
                </Link>
                <Link href="https://www.instagram.com/gypsymodebe/" className="p-2" isExternal>
                  <IGIcon className="w-8 h-8" />
                  <span className="sr-hide">Instagram</span>
                </Link>
                <Link href={`mailto:${CONTACT_EMAIL}`} className="p-2" isExternal>
                  <MailIcon className="w-8 h-8" />
                  <span className="sr-hide">Mail {CONTACT_EMAIL}</span>
                </Link>
              </div>
            </div>
          </div>

          <div className="mb-6">
            <div className="font-sans font-medium">{translate('language')}</div>
            <Select
              onChange={(evt) => {
                const value = evt.target.value?.trim().toLowerCase();
                if (value && locale !== value) {
                  changeLocale(value);
                }
              }}
              value={locale}
              appearance="inverted"
            >
              <Option value="nl">Nederlands</Option>
              <Option value="fr">Français</Option>
            </Select>
          </div>
        </div>

        <div className="flex flex-wrap mb-2 justify-center">
          <Link href="/policies/terms-of-sale" className="px-2">
            {translate('policies.termsOfSale')}
          </Link>
          <div>|</div>
          <Link href="/policies/privacy" className="px-2">
            {translate('policies.privacyPolicy')}
          </Link>
          <div>|</div>
          <Link href="/policies/cookies" className="px-2">
            {translate('policies.cookiePolicy')}
          </Link>
        </div>

        <div className="flex flex-wrap justify-center">
          <div className="mx-1">Gypsy Mode © {new Date().getFullYear()}</div>
        </div>
      </div>
    </footer>
  );
}
