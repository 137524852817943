import React from 'react';

import Header from './header';
import Footer from './footer';
import CookiePopup from './cookie-popup';
import { LOGO_URL } from '../../constants';

export interface Props {
  children: React.ReactNode;
  title?: string;
  description?: string;
  canonical?: string;
  image?: string | null;
}

export const PageLayout = (props: Props) => {
  let { children, title, description, canonical, image } = props;

  return (
    <div className="font-sans">
      <Header title={title} description={description} canonical={canonical} image={image ?? LOGO_URL} />
      <main className="min-h-screen max-w-6xl mx-auto px-4 mt-4 mb-24 text-raw-umber">{children}</main>
      <Footer />
      <CookiePopup />
    </div>
  );
};

export default PageLayout;
