import { FlattenedObject, t } from '../i18n/utils';
import { TranslationKey } from '../i18n/types';
import { useCallback } from 'react';
import { useLocale } from './useLocale';

export function useTranslate() {
  const locale = useLocale();

  return useCallback(
    (key: TranslationKey, values: FlattenedObject = {}) => {
      return t(locale, key, values);
    },
    [locale]
  );
}
