import React from 'react';

import Notification from './notification';
import notificationEmitter, { Notification as NotificationType } from '../../event-emitters/notifications';

const TIMEOUT = 2500;

type Props = {};

type State = {
  notifications: { [key: string]: NotificationType };
};

export default class NotificationContainer extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      notifications: {},
    };
  }

  componentDidMount() {
    notificationEmitter.on('notification', (n) => {
      let notificationClone = { ...this.state.notifications };
      let timestamp = Date.now();
      notificationClone[timestamp] = n;
      this.setState({ notifications: notificationClone }, () => {
        setTimeout(() => {
          let notificationClone = { ...this.state.notifications };
          delete notificationClone[timestamp];
          this.setState({ notifications: notificationClone });
        }, TIMEOUT);
      });
    });
  }

  render() {
    return (
      <div className="absolute w-auto right-0">
        {Object.keys(this.state.notifications)
          .sort()
          .map((k) => {
            let notification = this.state.notifications[k];

            return (
              <Notification key={k} notificationType={notification.type} title={notification.data.title}>
                {notification.data.content}
              </Notification>
            );
          })}
      </div>
    );
  }
}
