import { ChevronDownIcon } from '@heroicons/react/24/solid';
import classNames from 'classnames';

export interface ISelectProps extends React.SelectHTMLAttributes<HTMLSelectElement> {
  appearance?: 'default' | 'inverted';
  error?: boolean;
}

export const Select: React.FC<ISelectProps> = (props) => {
  const { children, appearance = 'default', disabled, error, ...otherProps } = props;

  return (
    <div className="relative">
      <select
        {...otherProps}
        disabled={disabled}
        className={classNames(
          'appearance-none border rounded-none py-2 pl-2 pr-8 flex items-center justify-start overflow-hidden w-full',
          {
            'text-gray-400 border-gray-200 bg-gray-200': disabled,
            'hover:border-raw-umber text-raw-umber': !disabled,
            'border-raw-umber': !disabled && !error,
            'border-red-400': error,
            'bg-isabelline': !disabled && appearance === 'default',
            'bg-white': !disabled && appearance === 'inverted',
          }
        )}
      >
        {children}
      </select>

      <div className="absolute right-0 top-0 mt-2 mr-1">
        <ChevronDownIcon
          className={classNames('w-6 h-6', {
            'text-gray-400': disabled,
            'text-raw-umber': !disabled,
          })}
        />
      </div>
    </div>
  );
};

export const Option: React.FC<React.SelectHTMLAttributes<HTMLOptionElement>> = (props) => {
  const { children, ...otherProps } = props;

  return <option {...otherProps}>{children}</option>;
};
